import axios from "axios";
import { DateTime } from "luxon";
import { v4 as uuidv4 } from "uuid";

function stringToColor(string: string): string {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.substr(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

function getAgeFromDate(date: string): number {
    const _date = new Date(date);
    const now = new Date();
    const currentYear = now.getFullYear();
    const yearDiff = currentYear - _date.getFullYear();
    const birthdayThisYear = new Date(currentYear, _date.getMonth(), _date.getDate());
    const hasHadBirthdayThisYear = (now >= birthdayThisYear);

    return hasHadBirthdayThisYear
        ? yearDiff
        : yearDiff - 1;
}

function extractLetterFromCompanyName(name: string): string {
    const re = /[a-zA-Z]/;
    return name.split(" ")
        .slice(0, 2)
        .map((word) => {
            for (const i of word) {
                if (re.test(i)) {
                    return i;
                }
            }
            return "";
        })
        .join("")
        .toUpperCase();
}

function extractFirstLetterFromCompanyName(name: string): string {
    // const re = /[a-zA-Z]/;
    return name.charAt(0)
        .toUpperCase();
}

function redirectTo(url: string) {
    window.location.href = url;
}

function chunkArray(arr: any[], size: number): any[] {
    const result: any[] = [];
    let temp = 0;
    let a: any[] = [];
    for (const item of arr) {
        a.push(item);
        temp += 1;
        if (temp === size) {
            result.push(a);
            a = [];
            temp = 0;
        }
    }
    return result;
}

function hasKey(obj: any, key: keyof any) {
    return key in obj;
}
function convertToFormData(params: any) {
    const requestFormData = new FormData();
    for (const key in params) {
        if (hasKey(params, key)) {
            if (Array.isArray(params[key])) {
                params[key].forEach((item: any) => {
                    requestFormData.append(key, item as string | Blob);
                });
            } else {
                requestFormData.append(key, params[key] as string | Blob);
            }
        }
    }
    return requestFormData;
}

function initilizedArray(n: number): number[] {
    return Array(n).fill(1).map((a, i) => i + 1);
}

function duplicateArrayNTimes<T>(obj: any, n: number): T[] {
    return Array(n).fill(obj);
}

function commaFormattedCurrency(str: string | number, minimumFractionDigits = 0): string {
    return Number(str).toLocaleString("en-US", {
        currency: "GBP",
        style: "currency",
        minimumFractionDigits: minimumFractionDigits,
    });
}

function formatNumberCompact(num: number, maximumFractionDigits = 1) {
    const truncateFractionAndFormat = (parts: { type: any; value: any; }[], digits: number) => {
        if (digits === 0) {
            // console.log(parts?.filter(i => i.type === "integer" || i.type === "compact"));
            return parts?.filter(i => i.type === "integer" || i.type === "compact")
                .reduce((prev, next) => prev + next.value, "");
        }
        return parts.map(({ type, value }) => {
            if (type !== "fraction" || !value || value.length < digits) {
                return value;
            }

            let retVal = "";
            for (let idx = 0, counter = 0; idx < value.length && counter < digits; idx++) {
                if (value[idx] !== "0") {
                    counter = counter + 1;
                }
                retVal += value[idx];
            }
            return retVal;
        }).reduce((string, part) => string + part);
    };
    const formatter = new Intl.NumberFormat("en-GB", {
        notation: "compact",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
    });

    return truncateFractionAndFormat(formatter.formatToParts(num), maximumFractionDigits);
}

function formatNumberCompactWithCurrency(num: number, maximumFractionDigits = 1) {
    let prefix = "";
    if (num < 0) {
        prefix += "-£";
    } else {
        prefix += "£";
    }
    return prefix + Intl.NumberFormat("en-GB", {
        notation: "compact",
        maximumFractionDigits,
    }).format(Math.abs(num));
}

function commaSeperatorFormat(str: string | number): string {
    if (str) {
        return Number(str).toLocaleString("en-US");
    }
    return "";
}

function downloadPdf(file: any, fileName: string) {
    if (window) {
        // window.open(url, "_blank");
        const a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.setAttribute("download", fileName);
        const url = window.URL.createObjectURL(file);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }
}

function downloadFile(url: string) {
    if (window) {
        const link = document.createElement("a");
        let name: string | string[] = (url?.split("/") || []);
        name = name[name?.length - 1];
        link.setAttribute("download", name);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        link.remove();
    }
}

function downloadExcelFile(url: any) {
    const link: any = document.createElement("a");
    link.href = url;

    // Append to html link element page
    document.body.appendChild(link);

    // Start download
    link.click();

    // Clean up and remove the link
    link.parentNode.removeChild(link);
}

function getFinancialRangeVariableLabel(i: number) {
    if (i > 0) {
        return `Growth over last ${i === 1 ? "" : i} ${i === 1 ? "year" : "years"}`;
    }
    if (i < 0) {
        i = Math.abs(i);
        return `Decline over last ${i} ${i === 1 ? "year" : "years"}`;
    }
    return "";
}

function capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function capitalizeFirstLetterOtherSmall(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function sentenceCase(str: string | any) {
    const newString = str?.toLowerCase().replace(/(^\s*\w|[\.\!\?]\s*\w)/g, function (c: string) {
        return c.toUpperCase();
    });
    return newString;
}

function maxElementInArray(arr: any[]) {
    let max = -Infinity;
    for (const i of arr) {
        if (i > max) {
            max = i;
        }
    }
    return max;
}

function formatdefineValueForTooltip(value: any) {
    return value === 1 ? `${value} company` : `${value} companies`;
}

const setDisplayStyle = (type = "none") => {
    if (document) {
        const selector = document.querySelector("#print-insight-charts");
        if (selector) {
            selector.setAttribute("style", `display:${type}`);
        }
    }
};

function toTitleCase(str: string) {
    return str.replace(
        /\w\S*/g,
        function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
    );
}

function isPositiveInt(str: string) {
    if (!str) {
        return false;
    }
    return /^\+?[1-9]\d*$/.test(str);
}

function getCurrentPageNumber(pageNumber: any) {
    return pageNumber && isPositiveInt(pageNumber as string) ? Number(pageNumber) : 1;
}

function isOnline() {
    return navigator?.onLine;
}

function getInvestorType(str: string) {
    if (str.length > 0) {
        return str.replace("has_vc", "Venture Capital")
            .replace("has_pe", "Private Equity")
            .replace("has_comp", "Corporate Venture Capital")
            .replace("has_officer", "Officer")
            .replace("has_angel", "Angel")
            .replace("has_family", "Family")
            .replace("has_no_investment", "No Investments");
    }
    return "-";
}

function formatAsPercentage(val: number | string) {
    return `${val}%`;
}

function rankNumbers(arr: Array<number>) {
    return arr.map((x, y, z) => z.filter(w => w < x).length + 1);
}

function getUniqueId() {
    return uuidv4();
}

function convertToPercentage(item: any) {
    return (item * 100).toFixed(2);
}

function handleAxiosError(error: any, toast: any) {
    if (axios.isAxiosError(error)) {
        if (error.response?.data?.message) {
            toast.error(error.response?.data?.message,);
        }
    }
}

function handleAxiosSuccess(message: string, toast: any) {
    toast.success(message, { position: "bottom-left" });
}

function insertItemAtSpecificIndex(arr: any[], index: number, item: any) {
    return [...arr.slice(0, index), item, ...arr.slice(index)];
}

function slugify(str: string) {
    return str.toLowerCase()
        .replace(/[^\w\s\']|_/g, "")
        .replace(/\s+/g, " ")
        .replace(/ /g, "-");
}

function formatDate(date: string, format?: string): string {
    return DateTime.fromISO(date).toFormat(format ? format : "LLL yyyy");
}

function getFlooredFixed(v: number, d: number) {
    return (Math.floor(v * Math.pow(10, d)) / Math.pow(10, d)).toFixed(d);
}

function formatCurrencyForInsight(value: number, limit = 10 ** 5) {
    value = Math.floor(value);
    return Math.abs(value) < limit ? commaFormattedCurrency(String(value)) : `${value < 0 ? "-" : ""}£${formatNumberCompact(value, 1)}`;
}

function getOptimizedImage(url: string) {
    return `/_next/image?url=${encodeURIComponent(url)}&w=1920&q=75`;
}

export {
    stringToColor,
    extractLetterFromCompanyName,
    redirectTo,
    chunkArray,
    convertToFormData,
    initilizedArray,
    duplicateArrayNTimes,
    getAgeFromDate,
    commaFormattedCurrency,
    formatNumberCompact,
    commaSeperatorFormat,
    downloadFile,
    downloadPdf,
    getFinancialRangeVariableLabel,
    capitalizeFirstLetter,
    capitalizeFirstLetterOtherSmall,
    maxElementInArray,
    downloadExcelFile,
    formatNumberCompactWithCurrency,
    setDisplayStyle,
    formatdefineValueForTooltip,
    toTitleCase,
    extractFirstLetterFromCompanyName,
    isPositiveInt,
    getCurrentPageNumber,
    isOnline,
    sentenceCase,
    getInvestorType,
    formatAsPercentage,
    rankNumbers,
    getUniqueId,
    convertToPercentage,
    handleAxiosError,
    handleAxiosSuccess,
    insertItemAtSpecificIndex,
    slugify,
    formatDate,
    getFlooredFixed,
    formatCurrencyForInsight,
    getOptimizedImage,
};
